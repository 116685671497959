/*
    Default Laravel styles.
*/
html, body {
    background-color: #fff;
    color: #636b6f;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    height: 100vh;
    margin: 0;
}

.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
}

.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

/*
 - Custom Stuff Below.
*/

header,
footer {
    .links {
        display: block;
        float: right;
        list-style: none;

        li {
            list-style: none;
        }

    }
}

header {
    .links {
        padding-top: 10px;
    }
}

.main-content {
    margin-top: 20px;
    margin-bottom: 20px;
}

.error {
    color: red;
    font-weight: bold;
}
.error span {
    font-style: italic;
}