@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.eot');
    src: url('../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.woff') format('woff'),
    url('../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.ttf') format('truetype'),
    url('../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}

@import "components/base";
@import "components/calendar";
@import "components/stepwizard";
@import "components/login_modal";
@import "components/card";
@import "components/loader";